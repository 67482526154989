<template>
    <div class="home-warpper"
        v-infinite-scroll="loadMore"
        :infinite-scroll-disabled="loadBusy"
        infinite-scroll-distance="200"
    >
        <div class="head-content">
            <img src="./img/banner-bg.png" @click="publishProject" />
        </div>

        <div class="content-list">
            <h1 class="label-title">爱心救助</h1>
            <div v-if="listData.length" class="list-container">
                <div v-for="(item, index) in listData" :key="index" class="item-group" @click="goDetail(item.uuid)">
                    <div class="cell-lt">
                        <span
                            v-if="item.project_thumb"
                            :style="{'backgroundImage':'url('+ item.project_thumb +'?x-image-process=style/thumb.png)'}"
                            class="project_thumb">
                        </span>
                        <img v-else class="project_thumb" src="./img/logo.png" />
                    </div>

                    <div class="cell-rt">
                        <div class="publish-info">
                            <img class="publish-user-avatar" v-lazy="item.issuer_face_url" />
                            <span class="publish-name">{{item.issuer_name}}</span>
                        </div>
                        <span class="project-title">{{item.title}}</span>
                        <div class="project-info">
                            <span class="raise-amount">已筹：{{item.accumulative_number/100}}元</span>
                            <span class="target-amount">目标：{{item.raisefunds_number/100}}元</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="nodata-wraper" v-if="!nodata && listData.length">
            <img src="./img/not-data.png" class="not-data" />
            <span class="not-data-desc">暂无项目</span>
        </div>

        <NavBar :activeIndex="0" />
    </div>
</template>

<script>
import Vue from 'vue';
import NavBar from '../../components/NavBar/index.vue';
import api from './js/api';

import { shareCtrl, tracking } from '@/common/js/mmcModule';
import infiniteScroll from 'vue-infinite-scroll';
Vue.use(infiniteScroll);

export default {
    name:'Home',
    data () {
        return {
            listData: [],
            nextPage: 1,
            page_total: 1,
            loadBusy: true,
            nodata: false,
        }
    },
    components: {
        NavBar
    },
    created() {
        showLoading('加载中');
        this.getListData();

        tracking.init();
    },

    methods: {
        getListData() {
            const time = Math.round(new Date() / 1000);
            api.getList({
                page: this.nextPage,
                time,
            }).then((res) => {
                hideLoading();
                console.log(res);
                this.listData.push.apply(this.listData, res.data.list);

                this.nodata = this.listData.length > 0 ? true : false;
                this.loadBusy = false;

                this.page_total = res.data.page_total;
                this.nextPage++;
            });

            shareCtrl.init({
                title: '【宠物筹】爱心接力，他们在等待你的帮助！',
                desc: '大病筹款就用宠物筹。',
                url: `${window.BasePath}fund/indexList`,
                titleMoment: '【宠物筹】爱心接力，他们在等待你的帮助！',
            });
        },

        loadMore() {
            if (this.nextPage > this.page_total || this.loadBusy) return;
            this.loadBusy = true;

            showLoading('加载中');
            setTimeout(() => {
                this.getListData();
            }, 500);
        },


        publishProject() {
            location.href = `${window.BasePath}fund/publish/guide`;
        },

        goDetail(projuuid) {
            location.href = `${window.BasePath}fund/project/detail?projuuid=${projuuid}`;
        }
    }
}
</script>

<style lang='less' scoped>
.bold {
    font-weight: 600;
}
.home-warpper {
    padding-bottom: 70px;
}

.head-content {
    background: #F2F2F2;
    padding-bottom: 14px;
    img {
        display: block;
        width: 100%;
    }
}

.content-list {
    color: #F2F2F2;
}

.label-title {
    font-size: 21px;
    color: #333333;
    font-weight: 600;
    line-height: 1;
    padding: 18px 0;
    text-align: left;
    border-bottom: 1px solid #EAEAEA;
    margin: 0 16px;
}

.list-container {
    padding: 20px 16px;
}

.item-group {
    display: flex;
    align-items: center;
    margin-bottom: 35px;

    .cell-lt {
        margin-right: 14px;
        flex-shrink: 0;
        width: 88px;
        height: 88px;
        border-radius: 8px;
        overflow: hidden;
    }
    .cell-rt {
        text-align: left;
        flex: 1;
    }
    .publish-info {
        display: flex;
        margin-bottom: 8px;
        align-items: center;
        width: 100%;
    }
    .publish-user-avatar {
        width: 22px;
        height: 22px;
        border-radius: 25px;
        margin-right: 9px;
    }
    .publish-name {
        font-size: 12px;
        color: #333333;
        text-align: left;
        display: block;
        width: 50%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600;
    }
    .project_thumb {
        display: block;
        width: 88px;
        height: 88px;
        flex-shrink: 0;
        background-size: cover;
    }

    .project-title {
        display: block;
        margin-bottom: 12px;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        color: #333333;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        min-height: 40px;
    }

    .project-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        span {
            font-size: 12px;
            color: #999999;
            line-height: 1;
        }
    }

}

.nodata-wraper{
    margin: 0 20px;

    .not-data {
        width: 200px;
        height: auto;
        display: block;
        margin: 0 auto 20px;
    }
    .not-data-desc {
        font-size: 17px;
        color: #333333;
        text-align: center;
        line-height: 54px;
    }
}
</style>
